import { create } from 'zustand'

interface PlaybackState {
    isPlaying: boolean
    title: string
    artist: string
    album: string
    cover: string
    duration: number
    position: number

    setPlaying: (isPlaying: boolean) => void
    setData: (data: {
        title: string
        artist: string
        album: string
        cover: string
        duration: number
        position: number
    }) => void
    setPosition: (position: number) => void
    advancePosition: () => void
}


export const usePlayback = create<PlaybackState>((set) => ({
    isPlaying: false,
    title: '',
    artist: '',
    album: '',
    cover: '',
    duration: 0,
    position: 0,

    setPlaying: (isPlaying: boolean) => set({ isPlaying }),
    setData: (data: {
        title: string
        artist: string
        album: string
        cover: string
        duration: number
        position: number
    }) => set(data),
    setPosition: (position: number) => set({ position }),
    advancePosition: () => set((state) => ({ position: state.position + 1000 })),
}))