import { useLocation } from "react-router-dom";
import Overlay from "./Overlay";
import '.'

function App() {
  const path = useLocation();

  switch (path.pathname) {
    case "/":
      {
        const scope = "user-read-private user-read-email user-read-playback-state";
        const redirect_uri = "https://overlay.kocity.gay/confirm";
        const state = "spotify_auth_state";

        if (path.search.includes("key")) {
          return <Overlay />
        } else {
          var url = "https://accounts.spotify.com/authorize";
          url += "?response_type=token";
          url +=
            "&client_id=" +
            encodeURIComponent("93090cb9ff344c9e87f76acd6eefc4e9");
          url += "&scope=" + encodeURIComponent(scope);
          url += "&redirect_uri=" + encodeURIComponent(redirect_uri);
          url += "&state=" + encodeURIComponent(state);

          window.location.href = url;
        }
      }
      break;
    case "/confirm": {
      const hash = path.hash
        .substring(1)
        .split("&")
        .reduce(function (initial: any, item) {
          if (item) {
            var parts = item.split("=");
            initial[parts[0]] = decodeURIComponent(parts[1]);
          }
          return initial;
        }, {});

      window.location.href = `https://overlay.kocity.gay/?key=${hash.access_token}`;
      break;
    }
    default:
      return <div>Not Found</div>;
  }

  return <div>App</div>;
}

export default App;
