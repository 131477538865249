import {
  Box,
  Avatar,
  Typography,
  LinearProgress,
  Backdrop,
} from "@mui/material";
import { usePlayback } from "./states/Playback";
import { useEffect, useState } from "react";
import axios from "axios";
import { msToTimestamp } from "./functions";

const maps = [
  "Alien Smash Site",
  "Back Alley Brawl",
  "Concussion Yard",
  "Dueling Docks",
  "Galaxy Burger",
  "Holowood Drive-In",
  "Jukebox Junction",
  "Knockout Roundabout",
  "Lockdown Throwdown",
  "Rooftop Rumble",
  "Sludge Works",
  "The Hideout",
];

function Overlay() {
  const playbackState = usePlayback();
  const [map, setMap] = useState(0);
  const [tokenValid, setTokenValid] = useState(false);

  const [tint, setTint] = useState({
    light: "#ffffff",
    dark: "#000000",
  });

  useEffect(() => {
    const key = window.location.search.split("=")[1];

    const update = () => {
      axios
        .get("https://api.spotify.com/v1/me/player", {
          headers: {
            Authorization: `Bearer ${key}`,
          },
        })
        .then((res) => {
          const data: Types.SpotifyData = res.data as Types.SpotifyData;

          setTokenValid(true);
          playbackState.setPlaying(data.is_playing);
          playbackState.setData({
            title: data.item.name,
            artist: data.item.artists[0].name,
            album: data.item.album.name,
            cover: data.item.album.images[0].url,
            duration: data.item.duration_ms,
            position: data.progress_ms,
          });
        })
        .catch((err) => {
          console.log(err);
          setTokenValid(false);
        });
    };

    const interval = setInterval(update, 5000);

    update();
    return () => clearInterval(interval);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      if (playbackState.isPlaying) {
        playbackState.advancePosition();
      }
    }, 1000);

    return () => clearInterval(interval);
  }, [playbackState]);

  useEffect(() => {
    // Get the dominant color of the album cover
    const img = new Image();
    img.crossOrigin = "anonymous";
    img.src = playbackState.cover;

    img.onload = () => {
      const canvas = document.createElement("canvas");
      const ctx = canvas.getContext("2d");
      ctx?.drawImage(img, 0, 0);

      const imageData = ctx?.getImageData(0, 0, img.width, img.height);
      const data = imageData?.data;

      const colorCount = 50;
      const colorStep = Math.floor(data!.length / colorCount);

      const colors = [];
      for (let i = 0; i < data!.length; i += colorStep) {
        const r = data![i];
        const g = data![i + 1];
        const b = data![i + 2];

        colors.push([r, g, b]);
      }

      const red = colors.reduce((acc, val) => acc + val[0], 0) / colors.length;
      const green =
        colors.reduce((acc, val) => acc + val[1], 0) / colors.length;
      const blue = colors.reduce((acc, val) => acc + val[2], 0) / colors.length;

      const color = rgbToHsl(red, green, blue);

      const hue = Math.floor(color[0] * 360);
      const saturation = Math.floor(color[1] * 200);
      const lightness = Math.floor(color[2] * 100);

      const light = `hslA(${hue}, ${saturation}%, ${clamp(
        lightness + 10,
        0,
        100
      )}%, 0.7)`;
      const dark = `hslA(${hue}, ${saturation}%, ${clamp(
        lightness - 10,
        0,
        100
      )}%, 0.9)`;

      setTint({
        light,
        dark,
      });
    };

    const map = Math.floor(Math.random() * maps.length);
    setMap(map);

    return () => {
      img.onload = null;
    };
  }, [playbackState.cover]);

  if (!tokenValid)
    return (
      <Backdrop
        open={true}
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          gap: "10px",

          zIndex: 1000,
        }}
      >
        <LinearProgress
          color="error"
          sx={{
            width: "400px",
          }}
        />
        <Typography
          sx={{
            color: "white",
            fontSize: "1.5rem",
            fontWeight: "bold",
            textAlign: "center",
          }}
        >
          The Spotify Token expired. <br /> Please re-fetch the link
        </Typography>
        <LinearProgress
          color="error"
          sx={{
            width: "400px",
          }}
        />
      </Backdrop>
    );

  return (
    <Box
      id="overlay"
      sx={{
        position: "absolute",
        height: "125px",
        width: "400px",
        top: "calc(50% - 62.5px)",
        right: "-5px",
        borderRadius: "5px",

        transition: "all 0.5s ease-in",

        background: `linear-gradient(90deg, ${tint.light}, ${
          tint.dark
        }), url(https://cdn.kocity.xyz/assets/creator/backgrounds/maps/${encodeURIComponent(
          maps[map]
        )}.png)`,
        backgroundSize: "120%",
        backgroundPositionX: "center",

        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "center",
        gap: "10px",

        padding: "10px",
      }}
    >
      <Avatar
        src={playbackState.cover}
        sx={{
          minWidth: "100px",
          minHeight: "100px",

          width: "100px",
          height: "100px",

          backgroundSize: "cover",
          backgroundPosition: "center",
          borderRadius: "5px",
          boxShadow: "-7px 10px 10px rgba(0, 0, 0, 0.7)",
        }}
      />

      <Box
        sx={{
          height: "100%",
          width: "100%",

          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
          justifyContent: "center",

          pl: "7px",
        }}
      >
        <img
          src="/spotify.png"
          alt=""
          style={{
            width: "16px",
            height: "16px",
            position: "absolute",
            top: "5px",
            right: "10px",
          }}
        />
        <Typography
          sx={{
            fontSize: "1.05rem",
            fontWeight: "800",
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
            width: "300px",
            maxWidth: "300px",

            mr: "30px",
          }}
        >
          {playbackState.title}
        </Typography>
        <Box
          sx={{
            ml: "3px",

            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "flex-start",
          }}
        >
          <Typography
            sx={{
              fontSize: "0.8rem",

              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
              width: "275px",
              fontWeight: "bold",
            }}
          >
            <strong
              style={{
                color: "#ffffff88",
                fontWeight: "normal",
              }}
            >
              by
            </strong>{" "}
            {playbackState.artist}
          </Typography>
          <Typography
            sx={{
              fontSize: "0.8rem",

              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
              width: "275px",
              fontWeight: "bold",

              mt: "-4px",
            }}
          >
            <strong
              style={{
                color: "#ffffff88",
                fontWeight: "normal",
              }}
            >
              on
            </strong>{" "}
            {playbackState.album}
          </Typography>
        </Box>

        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "flex-start",

            mt: "5px",
            pr: "35px",

            gap: "0px",

            width: "100%",
          }}
        >
          <LinearProgress
            variant="determinate"
            value={Math.floor(
              (playbackState.position / playbackState.duration) * 100
            )}
            sx={{
              width: "100%",
              height: "5px",
              borderRadius: "5px",
              backgroundColor: "grey",
              "& .MuiLinearProgress-bar": {
                backgroundColor: "white",
              },
            }}
          />
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-between",
              width: "100%",
            }}
          >
            <Typography
              sx={{
                fontSize: "0.6rem",
                color: "#ffffffAA",
                width: "32px",
                minWidth: "32px",
                maxWidth: "32px",
                textAlign: "left",
              }}
            >
              {msToTimestamp(playbackState.position)}
            </Typography>

            <Typography
              sx={{
                fontSize: "0.6rem",
                color: "#ffffffAA",
                width: "32px",
                minWidth: "32px",
                maxWidth: "32px",
                textAlign: "right",
              }}
            >
              {msToTimestamp(playbackState.duration)}
            </Typography>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}

export default Overlay;

function clamp(value: number, min: number, max: number) {
  return Math.min(Math.max(value, min), max);
}

function rgbToHsl(r: number, g: number, b: number) {
  r /= 255;
  g /= 255;
  b /= 255;

  const max = Math.max(r, g, b),
    min = Math.min(r, g, b);

  let h = 0,
    s = 0,
    l = (max + min) / 2;

  if (max !== min) {
    const d = max - min;
    s = l > 0.5 ? d / (2 - max - min) : d / (max + min);

    switch (max) {
      case r:
        h = ((g - b) / d + (g < b ? 6 : 0)) / 6;
        break;

      case g:
        h = ((b - r) / d + 2) / 6;
        break;

      case b:
        h = ((r - g) / d + 4) / 6;
        break;
    }
  }

  return [h, s, l];
}
