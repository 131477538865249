import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import { BrowserRouter } from "react-router-dom";
import { createTheme, CssBaseline, ThemeProvider } from "@mui/material";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <BrowserRouter>
    <ThemeProvider theme={createTheme({
      palette: {
        mode: "dark"
      }
    })}>
      <CssBaseline />
      <App />
    </ThemeProvider>
  </BrowserRouter>
);
